<template>
	<article class="assignments">
		<div class="site-wrapper rythm-v-l">
			<header class="page-header">
				<h1>{{ $t("Experts de garde") }}</h1>
			</header>

			<section class="section--assignments-toolbar rythm-v">
				<div class="toolbar card flex-item-100"
						 data-toolbar="assignments">

					<select name="select-year"
									class="vgt-select"
									v-model="year"
									@change="getAssignments">
						<option :value="selectedYear"
										v-for="(selectedYear, index) in years"
										:key="`assignments_year_${index}`">{{ selectedYear }}</option>
					</select>

					<select name="select-period"
									class="vgt-select"
									v-model="period"
									@change="getAssignments">
						<option :value="selectedPeriod"
										v-for="(selectedPeriod, index) in periods"
										:key="`assignments_period_${index}`">{{ $t(selectedPeriod.name) }}</option>
					</select>

					<div class="flex-item--25 flex-row flex-nowrap">
						<multiselect name="search"
												 v-model="searchExpert"
												 label="name"
												 :options="multiSelectOptions"
												 :close-on-select="true"
												 :placeholder="$t('Filtrer sur un expert')"
												 :multiple="false"
												 track-by="id"
												 :searchable="true"
												 :preselect-first="false"
												 :clear-on-select="false"
												 :preserve-search="false"
												 :internal-search="true"
												 selectLabel=""
												 :selectedLabel="$t('Sélectionné')"
												 :deselectLabel="$t('Supprimer')"
												 :tag-placeholder="$t('Ajouter')">
						</multiselect>
					</div>

					<div class="flex-item flex-row flex-gap">

						<button v-if="isCoordinator"
										type="button"
										class="button--outline"
										@click="openModal">
							<svg fill="none"
									 height="24"
									 viewBox="0 0 24 24"
									 width="24"
									 class="icon"
									 xmlns="http://www.w3.org/2000/svg"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="m1.46447 15.4645c.93768-.9377 2.20945-1.4645 3.53553-1.4645h8c1.3261 0 2.5979.5268 3.5355 1.4645.9377.9376 1.4645 2.2094 1.4645 3.5355v2c0 .5523-.4477 1-1 1s-1-.4477-1-1v-2c0-.7956-.3161-1.5587-.8787-2.1213s-1.3257-.8787-2.1213-.8787h-8c-.79565 0-1.55871.3161-2.12132.8787s-.87868 1.3257-.87868 2.1213v2c0 .5523-.44772 1-1 1-.552285 0-1-.4477-1-1v-2c0-1.3261.526784-2.5979 1.46447-3.5355z"/><path d="m9 4c-1.65685 0-3 1.34315-3 3s1.34315 3 3 3c1.6569 0 3-1.34315 3-3s-1.3431-3-3-3zm-5 3c0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5s-2.2386 5-5 5c-2.76142 0-5-2.23858-5-5z"/><path d="m19.0318 14.88c.138-.5347.6835-.8563 1.2182-.7182 1.0727.2769 2.023.9023 2.7017 1.7779.6787.8755 1.0475 1.9517 1.0483 3.0596v2.0007c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1.9993c0-.0001 0 .0001 0 0-.0006-.6646-.2218-1.3105-.629-1.8357-.4072-.5254-.9774-.9006-1.621-1.0668-.5347-.138-.8563-.6834-.7182-1.2182z"/><path d="m15.0312 2.88196c.137-.53502.6818-.8577 1.2168-.72071 1.0756.27538 2.0288.90088 2.7096 1.77789.6807.87701 1.0502 1.95565 1.0502 3.06586s-.3695 2.18885-1.0502 3.0659c-.6808.877-1.634 1.5025-2.7096 1.7779-.535.1369-1.0798-.1857-1.2168-.7208-.1369-.535.1857-1.0798.7208-1.21675.6453-.16522 1.2172-.54052 1.6257-1.06673.4084-.52621.6301-1.17339.6301-1.83952s-.2217-1.31331-.6301-1.83952c-.4085-.5262-.9804-.9015-1.6257-1.06673-.5351-.13699-.8577-.68176-.7208-1.21679z"/></g></svg>
							<span class="caption">{{ $t('Experts disponibles') }}</span>
						</button>

						<button v-if="isCoordinator"
										type="button"
										class="button--outline"
										@click="autoAssignments">
							<svg xmlns="http://www.w3.org/2000/svg"
									 width="24"
									 height="24"
									 fill="none"
									 stroke="currentColor"
									 stroke-width="2"
									 stroke-linecap="round"
									 stroke-linejoin="round"
									 viewBox="0 0 24 24"
									 class="icon feather-refresh-cw"><path d="M11.1 2.043c-.518.054-1.084.156-1.64.296-2.432.613-4.626 2.234-5.977 4.413-.408.658-.905 1.759-.984 2.18-.099.525.255.934.924 1.07.515.105.811-.111 1.073-.783.439-1.122.966-1.951 1.762-2.773A7.6 7.6 0 0 1 8.386 4.87a8.011 8.011 0 0 1 7.774.307c.611.373.956.667 2.671 2.278l1.623 1.525-1.898.02-1.898.02-.199.102C16.144 9.284 16 9.56 16 10c0 .428.144.713.442.88l.178.1 3.18.025c1.749.015 3.233.027 3.298.029.164.005.566-.184.68-.319.233-.277.226-.152.213-3.775l-.011-3.32-.089-.167c-.202-.377-.753-.551-1.262-.398-.251.076-.414.201-.52.398-.088.165-.089.194-.109 2.192l-.02 2.024-1.7-1.598c-.935-.879-1.844-1.715-2.02-1.858a10.11 10.11 0 0 0-4.62-2.068c-.82-.137-1.809-.177-2.54-.102M.597 13.05c-.271.115-.401.231-.501.446l-.095.204v3.26c0 2.062.014 3.313.04 3.405.107.385.396.597.855.626.485.031.803-.109.984-.433l.1-.178.02-2.025.02-2.025 1.68 1.58c1.916 1.802 2.111 1.971 2.803 2.433 1.185.791 2.596 1.325 4.137 1.565.641.1 2.111.098 2.76-.003a13.606 13.606 0 0 0 2.08-.529 12.317 12.317 0 0 0 2.02-1.024 10.014 10.014 0 0 0 3.797-4.68c.207-.531.242-.688.205-.935-.052-.344-.433-.646-.925-.733-.274-.049-.475-.014-.652.113-.178.126-.261.26-.442.716-.471 1.181-1.055 2.063-1.929 2.908a7.397 7.397 0 0 1-2.083 1.459c-2.23 1.075-4.76 1.061-7.011-.038-.508-.248-1.135-.647-1.5-.956a127.91 127.91 0 0 1-1.817-1.686l-1.596-1.5L5.463 15c1.864-.019 1.921-.022 2.077-.106.335-.179.438-.39.438-.894 0-.316-.015-.408-.09-.55-.104-.198-.314-.348-.568-.407-.116-.027-1.316-.046-3.369-.053-2.961-.009-3.201-.005-3.354.06" fill-rule="evenodd" fill="currentColor" stroke="none"/></svg>
							<span class="caption">{{ $t('Générer les affectations') }}</span>
						</button>
					</div>

				</div>
			</section>

			<section class="section--assignments">

				<div class="card shadow assignements-table-wrapper flex-item--100">
					<vue-good-table v-if="rows.length"
													:isLoading="loading"
													:id="`assignmentsTable-${lang}`"
													:ref="`assignmentsTable-${lang}`"
													:columns="columns"
													:rows="rows"
													styleClass="vgt-table striped"
													:select-options="{
					enabled: isCoordinator,
					selectOnCheckboxOnly: true,
					selectionText: $t('ligne(s) sélectionnée(s)'),
					clearSelectionText: 'Aucun',
					resetAfter: false,
				}"
													:search-options="{
					enabled: true,
					externalQuery: searchExpert,
					searchFn: handleSearch,
					// trigger: 'enter'
				}">
						<!-- Actions liées à la sélection multiple -->
						<div slot="selected-row-actions">
							<!-- Expert primaire français -->
							<multiselect name="group-select-primary-expert-lang_fr"
													 v-model="groupAssignments.expert_fr"
													 label="name"
													 :options="groupMultiSelectOptions"
													 :close-on-select="true"
													 :placeholder="$t('Expert principal français')"
													 :multiple="false"
													 track-by="id"
													 :searchable="true"
													 :preselect-first="false"
													 :clear-on-select="false"
													 :preserve-search="false"
													 :internal-search="true"
													 selectLabel=""
													 selectedLabel="Sélectionné"
													 deselectLabel="Supprimer"
													 tag-placeholder="Ajouter">
							</multiselect>

							<!-- Expert primaire allemand -->
							<multiselect name="group-select-primary-expert-lang_de"
													 v-model="groupAssignments.expert_de"
													 label="name"
													 :options="groupMultiSelectOptions"
													 :close-on-select="true"
													 :placeholder="$t('Expert principal allemand')"
													 :multiple="false"
													 track-by="id"
													 :searchable="true"
													 :preselect-first="false"
													 :clear-on-select="false"
													 :preserve-search="false"
													 :internal-search="true"
													 selectLabel=""
													 :selectedLabel="$t('Sélectionné')"
													 :deselectLabel="$t('Supprimer')"
													 :tag-placeholder="$t('Ajouter')">
							</multiselect>

							<button type="button"
											class="button--primary"
											@click="groupChangeExpert">
								<!-- Enregistrer</button> -->
								<span class="caption">{{ $t("Affecter") }}</span>
								<svg class="icon"
										 role="img"
										 xmlns="http://www.w3.org/2000/svg"
										 width="20"
										 height="16"
										 fill="none"
										 viewBox="0 0 20 16"><path fill="currentColor" fill-rule="evenodd" d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z" clip-rule="evenodd" /><path fill="currentColor" fill-rule="evenodd" d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z" clip-rule="evenodd" /></svg>
							</button>
						</div>

						<template slot="table-column"
											slot-scope="props">
							<span v-if="props.column.field == 'actions'"></span>
							<span>{{ $t(props.column.label) }}</span>
						</template>

						<template slot="table-row"
											slot-scope="props">

							<!-- Week -->
							<div class="flex-row flex-center-v"
									 v-if="props.column.field == 'formated_assignment_date'">
								<strong v-if="isCurrentWeek(props.row.date_start, props.row.date_end)">{{ props.row.formated_assignment_date }}</strong>
								<span v-else>{{ props.row.formated_assignment_date }}</span>
							</div>

							<!-- Expert primaire français -->
							<div class="flex-row flex-center-v"
									 v-if="props.column.field == 'expert_fr'">
								<multiselect v-if="isCoordinator"
														 name="select-primary-expert-lang_fr"
														 v-model="assignments[props.row.key].expert_fr"
														 label="name"
														 :options="multiSelectOptions"
														 :close-on-select="true"
														 :placeholder="$t('Choisissez un expert')"
														 :multiple="false"
														 track-by="id"
														 @input="changeExpert(props.row.key)"
														 :searchable="true"
														 :preselect-first="false"
														 :clear-on-select="false"
														 :preserve-search="false"
														 :internal-search="true"
														 selectLabel=""
														 :selectedLabel="$t('Sélectionné')"
														 :deselectLabel="$t('Supprimer')"
														 :tag-placeholder="$t('Ajouter')">
								</multiselect>
								<div v-else>
									{{ assignments[props.row.key].expert_fr.name }}
								</div>
							</div>

							<!-- Expert primaire allemand -->
							<div class="flex-row flex-center-v"
									 v-else-if="props.column.field == 'expert_de'">
								<multiselect v-if="isCoordinator"
														 name="select-primary-expert-lang_de"
														 v-model="assignments[props.row.key].expert_de"
														 label="name"
														 :options="multiSelectOptions"
														 :close-on-select="true"
														 :placeholder="$t('Choisissez un expert')"
														 :multiple="false"
														 track-by="id"
														 @input="changeExpert(props.row.key)"
														 :searchable="true"
														 :preselect-first="false"
														 :clear-on-select="false"
														 :preserve-search="false"
														 :internal-search="true"
														 selectLabel=""
														 :selectedLabel="$t('Sélectionné')"
														 :deselectLabel="$t('Supprimer')"
														 :tag-placeholder="$t('Ajouter')">
								</multiselect>
								<div v-else>
									{{ assignments[props.row.key].expert_de.name }}
								</div>
							</div>

						</template>
						<template slot="loadingContent">
							<svg class="loader"
									 width="44"
									 height="44"
									 viewBox="0 0 44 44"
									 xmlns="http://www.w3.org/2000/svg"
									 stroke="currentColor"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" /><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" /></circle></g></svg>

						</template>
					</vue-good-table>
				</div>
			</section>
		</div>

		<oncall-experts-modal :open="modalOncallExpertsVisible"
													:users="allExperts"
													@modalClose="modalClose"
													@input="toggleExpertOncall" />
	</article>
</template>

<style lang="scss">
@import "@/scss/assignments.scss";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { HTTP } from "../http-common.js";

// Vue Good Table
import { VueGoodTable } from "vue-good-table";

// Multiselect
import Multiselect from "vue-multiselect";

// Oncall Experts modal
import OncallExpertsModal from "../components/OncallExpertsModal.vue";

import { mapGetters } from "vuex";

// Assignments component
export default {
	name: "Assignments",
	props: {},
	components: { VueGoodTable, Multiselect, OncallExpertsModal },

	data: function () {
		return {
			loading: false,
			allExperts: [], // expert users
			experts: [], // experts accepting oncall
			assignments: [],
			groupAssignments: {
				expert_fr: "",
				expert_de: ""
			},
			rows: [],
			columns: [
				{
					labelFR: "Semaine",
					label: this.$t("Semaine"),
					field: "formated_assignment_date",
					sortable: false,
					tdClass: "",
					width: "360px",
				},
				{
					labelFR: "Expert principal français",
					label: this.$t("Expert principal français"),
					field: "expert_fr",
					sortable: false,
					tdClass: "",
				},
				{
					labelFR: "Expert principal allemand",
					label: this.$t("Expert principal allemand"),
					field: "expert_de",
					sortable: false,
					tdClass: "",
				},
			],
			year: null,
			period: null,
			searchExpert: "",
			modalOncallExpertsVisible: false,
		};
	},

	computed: {

		// oncall periods
		periods() {
			const pastYear = parseInt(this.year) - 1;
			const currenYear = parseInt(this.year);
			const nextYear = parseInt(this.year) + 1;
			return [
				{
					start: `${pastYear}-10-01`,
					end: `${currenYear}-01-31`,
					name: this.$t(`Octobre (%pastYear) à Janvier (%currenYear)`).replace('%pastYear', pastYear).replace('%currenYear', currenYear)
				},
				{
					start: `${currenYear}-01-31`,
					// start: `${currenYear}-02-01`,
					end: `${currenYear}-05-31`,
					name: this.$t("Février à Mai (%currenYear)").replace('%currenYear', currenYear)
				},
				{
					start: `${currenYear}-05-31`,
					// start: `${currenYear}-06-01`,
					end: `${currenYear}-09-30`,
					name: this.$t("Juin à Septembre (%currenYear)").replace('%currenYear', currenYear)
				},
				{
					start: `${currenYear}-09-30`,
					// start: `${currenYear}-10-01`,
					end: `${nextYear}-01-31`,
					name: this.$t(`Octobre (%currenYear) à Janvier (%nextYear)`).replace('%currenYear', currenYear).replace('%nextYear', nextYear)
				},
			];
		},

		years() {
			let now = new Date();
			return [now.getFullYear() - 1, now.getFullYear(), now.getFullYear() + 1, now.getFullYear() + 2];
		},

		// Store getters bindings
		...mapGetters({
			lang: "currentLang",
		}),

		// Current user data
		user() {
			return this.$store.state.userData;
		},

		// Is a regular user?
		user_is_regular: function () {
			let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
			return r < 2;
		},

		multiSelectOptions() {
			if (!this.experts) {
				return [];
			}
			let options = this.experts.map((expert) => {
				return { name: `${expert.firstname} ${expert.lastname}`, id: expert.id };
			});
			return options;
		},

		groupMultiSelectOptions() {
			let options = [...this.multiSelectOptions];
			options.unshift({ name: "Aucun", id: -1 });
			return options;
		},

		isCoordinator() {
			if (this.user.roles.includes(3)) {
				return true;
			}
			return false;
		},

	},


	methods: {

		// highlight current assignment (date & time check)
		isCurrentWeek(periodStart, periodEnd) {
			periodStart = new Date(periodStart);
			periodEnd = new Date(periodEnd);
			const today = new Date();
			return today >= periodStart && today <= periodEnd;
		},

		// get current period for the select
		getCurrentPeriod() {
			const currentPeriod = this.periods.filter(period => {
				const today = new Date();
				return today >= new Date(period.start) && today <= new Date(period.end);
			});
			return currentPeriod[0] || null;
		},

		toggleLoader(status = -1) {
			if (status === -1) this.loading = !this.loading;
			else this.loading = status;
		},

		// generate assignments confirmation
		autoAssignments: function (hash) {
			const message = this.$t("Générer les affectations %year&nbsp;?<br>Les affectations actuelles seront écrasées&nbsp;!").replace('%year', this.year);
			this.$toasted.show(`<div class="message-wrapper"><strong>${message}</strong></div>`, {
				containerClass: "toasted",
				theme: "primary",
				position: "center",
				duration: null,
				closeOnSwipe: false,
				action: [
					{
						text: this.$t("Annuler"),
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
							return;
						},
					},
					{
						text: this.$t("Confirmer"),
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
							this.processAutoAssignments();
						},
					},
				],
			});
		},

		// generate assignments 
		async processAutoAssignments() {
			this.toggleLoader(true);

			const u = await HTTP.post("/autoassignments", { year: parseInt(this.year) });
			if (u) {
				this.$toasted.global.appInfo({
					message: this.$t("Les affectations ont été générées."),
				});
			}
			this.toggleLoader(false);

			// reload
			await this.getExperts();
			this.getAssignments();
		},

		/**
		 * Toggle expert accept oncall status
		 *
		 * @param boolean status 
		 * @param int userID 
		 * @return void
		 */
		async toggleExpertOncall(status, userID) {
			status = status ? 1 : 0;
			let udpate = await this.$store.dispatch("UPDATE_EXPERT_ONCALL", { userID, status });
		},

		openModal() {
			this.modalOncallExpertsVisible = true;
		},

		// close modal
		modalClose(event) {
			this.modalOncallExpertsVisible = false;
			// reload list
			this.getAssignments();
		},


		// Fetch experts
		async getExperts() {
			this.toggleLoader(true);
			let users = await this.$store.dispatch("GET_EXPERT_USERS");

			// Remove Coordinators
			this.allExperts = users.filter(
				(u) => ((u.roles.includes("3") && u.roles.includes("5")) || (!u.roles.includes("3") && u.roles.includes("5"))) // Expert (ext) + Coordinator OR Expert only
			);

			// Only experts accepting on-call
			this.experts = this.allExperts.filter((u) => (parseInt(u.accept_oncall) === 1));

			this.toggleLoader(false);
		},

		getAssignments: function () {
			this.toggleLoader(true);
			const periodStart = this?.period?.start || '';
			const periodEnd = this?.period?.end || '';
			if (!periodStart || !periodEnd) return false;

			this.$store
				.dispatch("GET_ASSIGNMENTS", { periodStart, periodEnd })
				.then((response) => {
					this.toggleLoader(false);

					if (!response) {
						return this.$toasted.global.appInfo({
							message: this.$t("Cette période n’est pas accessible"),
						});
					}

					if (null !== response) {
						Object.entries(response).forEach((element) => {
							if (!element[1]) return;
							const assignmentKey = element[0];
							let assignment = element[1];

							// format dates
							const dateStart = new Date(assignment.date_start);
							const dateEnd = new Date(assignment.date_end);
							const options = { year: "numeric", month: "long", day: "numeric" };
							const dateStartString = dateStart.toLocaleDateString(this.lang, options);
							const dateEndString = dateEnd.toLocaleDateString(this.lang, options);

							assignment.assignment_date = `${dateStartString} - ${dateEndString}`;
							assignment.formated_assignment_date = `${dateStartString} - ${dateEndString}`;
							assignment.expert_fr = assignment.expert_fr ? this.addExpertDetails(assignment.expert_fr) : "";
							assignment.expert_de = assignment.expert_de ? this.addExpertDetails(assignment.expert_de) : "";
						});

						// this.assignments = response;
						this.assignments = { ...response };
						this.rows = Object.values(response);
					} else {
						this.$toasted.global.appInfo({
							message: this.$t("Cette période n’est pas accessible"),
						});
					}
				})
				.catch((error) => {
					this.toggleLoader(false);
					console.error("GET_ASSIGNMENTS", error);
				});
		},

		/**
		 * Add expert details to assignments
		 *
		 * @param {any} id
		 * @return Object
		 */
		addExpertDetails: function (id) {
			if (id === null || id === "-1") return null;
			let expert = this.experts.find((expert) => parseInt(expert.id) == parseInt(id));
			if (expert == "undefined" || typeof expert === "undefined") return null;

			return {
				id: id,
				name: expert.firstname + " " + expert.lastname,
			};
		},

		putAssignment: function (assignment) {
			this.$store.dispatch("PUT_ASSIGNMENT", assignment).then((success) => {
				if (!success) {
					this.$toasted.global.appError({
						message: "<strong>" + this.$t("Impossible d'enregistrer l'affectation&nbsp;!") + "</strong>",
					});
				}
			});
		},

		// On change la selection d'un expert primaire
		changeExpert: function (key) {
			let data = this.assignments[key];
			this.putAssignment(data);
		},

		groupChangeExpert: function (e) {
			let selectedLines = this.$refs[`assignmentsTable-${this.lang}`].selectedRows;
			let groupAssignments = this.groupAssignments;

			// On retire les propriétés vides de l'objet sauf si l'utilisateur a précisé aucun
			for (let key in groupAssignments) {
				//Les options aucun ont une valeur de -1
				if ((key == "expert_fr" || key == "expert_de") && typeof groupAssignments[key] == "object" && groupAssignments[key].id == -1) {
					groupAssignments[key] = "";
				} else if (Object.values(groupAssignments[key]).find((el) => el.id == -1)) {
					groupAssignments[key] = [];
				} else if (!groupAssignments[key] || groupAssignments[key].length === 0) {
					delete groupAssignments[key];
				}
			}

			selectedLines.forEach((selectedLine) => {
				let key = selectedLine.key;
				this.assignments[key] = { ...this.assignments[key], ...groupAssignments };
				this.changeExpert(key);
				this.clearGroupAssignments();
			});
		},

		clearGroupAssignments: function () {
			this.groupAssignments = {
				expert_fr: "",
				expert_de: "",
			};
		},

		// Selectionne les lignes qui correspondent à la recherche sur le nom d'un expert
		handleSearch: function (row, col, cellValue, searchExpert) {
			if (!searchExpert) {
				return cellValue;
			}

			for (const [key, value] of Object.entries(row)) {
				if ((key == "expert_fr" || key == "expert_de") && value.id == searchExpert.id) {
					return cellValue;
				}
			}
		},

		/**
		 * Update table labels
		 * Translations are not reactive to language change.
		 * Strings need to be translated from the french texts.
		 *
		 * @return void
		 */
		updateLabels() {
			// Columns labels
			this.columns.map((c) => {
				if (c?.labelFR) c.label = this.$t(c.labelFR);
				else c.label = this.$t(c.label);
			});

			// Date
			Object.entries(this.assignments).forEach((element) => {
				if (!element[1] || !element[1]["assignment_date"]) return;
				let assignment = element[1];
				const dateStart = new Date(assignment.date_start);
				const dateEnd = new Date(assignment.date_end);
				const options = { year: "numeric", month: "long", day: "numeric" };
				const dateStartString = dateStart.toLocaleDateString(this.lang, options);
				const dateEndString = dateEnd.toLocaleDateString(this.lang, options);
				assignment.formated_assignment_date = `${dateStartString} - ${dateEndString}`;
			});
		},

	},

	/**
	 * Mounted
	 */
	async mounted() {
		// Not for regular users
		if (this.user_is_regular) {
			this.$router.push("/dashboard");
		}

		// current year
		let now = new Date();
		this.year = now.getFullYear();

		// init default (current) period
		this.period = this.getCurrentPeriod();

		// Fetch data
		await this.getExperts();
		this.getAssignments();
	},

	watch: {
		lang: {
			immediate: false,
			handler(after, before) {
				if (after === before) return;
				this.updateLabels();
			},
		},
	},
};
</script>
