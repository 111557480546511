<template>
	<div class="modal-wrapper">
		<div class="modal-overlay"
				 @click="closeModal"></div>

		<section class="oncall-experts-modal modal card shadow"
						 role="dialog"
						 aria-labelledby="oncallExpertsModalTitle">
			<header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
				<h2 id="oncallExpertsModalTitle">{{ $t("Experts acceptant les gardes") }}</h2>
				<button class="button--icon"
								@click="closeModal">
					<svg role="img"
							 class="icon"
							 xmlns="http://www.w3.org/2000/svg"
							 width="14"
							 height="14"
							 fill="none"
							 viewBox="0 0 14 14"><title>{{ $t("Fermer") }}</title><path fill="currentColor" fill-rule="evenodd" d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z" clip-rule="evenodd" /><path fill="currentColor" fill-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" clip-rule="evenodd"/></svg>
				</button>
			</header>

			<div class="users-filter">
				<div class="input-group">
					<label for="users-filter">{{ $t("Rechercher un expert") }}&nbsp;:</label>
					<input type="text"
								 name="users-filter"
								 v-model="filter.value" />
				</div>
			</div>

			<form>
				<ul role="list"
						class="user-list"
						v-if="users">
					<li v-for="user in filteredList"
							:key="user.id">

						<user-avatar :user="user" />

						<div class="input-group--inline">
							<label>
								<input type="checkbox"
											 name="accept_oncall"
											 :checked="user.accept_oncall"
											 @change="handleCheck($event, user)">
								<span class="caption">{{ $t('Accepte les gardes') }}</span>
							</label>
						</div>
					</li>
				</ul>
			</form>
		</section>
	</div>
</template>

<script>
// User Avatar
import UserAvatar from "./UserAvatar.vue";
import { replaceDiacritics } from "../libs/helpers.js";

export default {
	name: "OncallExpertsModal",
	props: ["users"],
	components: { UserAvatar },

	data: function () {
		return {
			filter: {
				value: "",
			},
		};
	},

	methods: {
		closeModal: function () {
			this.$emit("modalClose");
		},

		// Emit event to parents
		handleCheck: function (event, user) {
			this.$emit("input", event.target.checked, user.id);
		},
	},

	computed: {
		// Filter experts
		filteredList: function () {
			let searched = this.filter.value.toLowerCase();
			return this.users.filter((user) => {
				let searched = replaceDiacritics(this.filter.value.toLowerCase());
				return (
					replaceDiacritics(user.firstname.toLowerCase()).includes(searched) ||
					replaceDiacritics(user.lastname.toLowerCase()).includes(searched) ||
					replaceDiacritics(user.specialty.toLowerCase()).includes(searched)
				);
			});
		},
	},
};
</script>


<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

section>*+* {
	margin-top: 1rem;
}

.oncall-experts-modal {
	width: 40rem;
	max-width: 100%;
}

.user-list {
	max-height: 24rem;
	overflow: scroll;

	li {
		display: flex;
		flex-flow: row wrap;
		align-content: center;
		align-items: center;
		justify-content: space-between;

		&+li {
			margin-top: 1rem;
		}

		&>*+* {
			margin-left: 1rem;
		}
	}
}

@media (max-width: 460px) {
	.user-list {
		max-height: 100%;
		overflow: initial;
		padding-bottom: 1rem;

		li {
			justify-content: stretch;

			.tag {
				display: none;
			}

			.button--primary {
				flex-basis: 100%;
			}

			&>*+* {
				margin: 1rem 0 0;
			}

			&+li {
				margin-top: 2rem;
			}
		}
	}
}

.user-card {
	flex-grow: 1;
}

.assigned {
	color: #068200;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;

	.icon {
		height: auto;
		width: 0.875em;
		flex-basis: auto;
		margin-right: 0.5em;
	}
}

.assigned,
.button--primary {
	flex-basis: 8rem;
	justify-content: center;
}
</style>